.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 0;
}

.card-body {
  padding-left: 40px;
  padding-right: 40px;
}

.card-navbar {
  margin-top: -$header-spacer;
  margin-bottom: $header-spacer;
}

.card-collapse {
  .card-header {
    padding: map-get($spacers, 1) map-get($spacers, 2);

    .toggle {
      color: $gray-600;
      margin-top: 0.3em;
      width: 1.2em;
      height: 1.2em;

      transition: all 0.25s ease-in-out;
    }

    button.collapsed + .toggle {
      transform: rotate(0deg);
    }

    button:not(.collapsed) + .toggle {
      transform: rotate(180deg);
    }
  }
}
