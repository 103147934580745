.alert {
  button.close {
    text-shadow: none;
  }

  span.icon {
    font-size: 1.5rem;
  }
}

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    button.close {
      color: $value;
    }
  }
}
