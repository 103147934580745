.social-hub-image img {
  max-height: 300px;
  max-width: 300px;
}

.attacking-zone-icon {
  font-size: 7em;
  transform: rotate(-90deg);
  color: $keepr-deep-blue;
}

.action-areas {
  position: relative;
  background-image: asset_url("field_horizontal.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: contain;
  min-height: 200px;
  max-width: 280px;
  filter: invert(25%) sepia(69%) saturate(0%) hue-rotate(295deg) brightness(93%) contrast(83%);
}

.home-stat-color {
  color: $keepr-cyan !important;
}

.mentio-item { color: $gray-900; background-color: $white;}
.mentio-item.active { color: $gray-900; background-color: $gray-200;}
