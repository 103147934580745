.football-field {
  position: relative;
  background-image: asset_url("field.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: visible;
  line-height: 1.2;
  min-width: 400px;
  min-height: 500px;
}

.tactical-formation__home,
.tactical-formation__away {
  display: flex;
  position: absolute;
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
}

.football-field {
  display: flex;
}

.tactical-formation__away {
  top: 50%;
}

.football-field__pitch,
.football-field__pitch .col-12 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.football-field__pitch .row {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.small-logo {
  height: 36px;
}
