.nav-keepr {
  .nav-link {
    font-size: $font-size-sm;
    font-weight: bold;
    color: $nav-keepr-link-color;
    border-bottom: $nav-keepr-border-width solid transparent;

    @include hover-focus {
      border-color: $nav-keepr-link-hover-border-color;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-keepr-link-active-color;
    background-color: $nav-keepr-link-active-bg;
    border-bottom: $nav-keepr-border-width solid $nav-keepr-active-border-color;
  }

  .dropdown-item {
    font-weight: bold;
  }
}
