.dropdown-menu {
  box-shadow: $dropdown-box-shadow;

  .dropdown-item {
    font-size: $font-size-sm;
  }
}

.dropdown-without-caret {
  .dropdown-toggle::after {
    display:none
  }
}
