.corner-banner {
  z-index: 2000;
  color: #F47D31;
  background-color: #333;
  border: 3px solid #F47D31;
  font-weight: bold;
  -moz-box-shadow: 2px 2px 20px #888;
  -moz-transform: rotate(-45deg);
  -moz-transform-origin: 50% 50%;
  -webkit-transform: rotate(-45deg);
  -webkit-transform-origin: 50% 50%;
  position: fixed;
  line-height: 30px;
  width: 300px;
  bottom: 35px;
  right: -95px;
  text-align: center;
}
