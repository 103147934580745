.backlink {
  & > a {
    font-size: 1rem;
    color: $gray-600;

    &:hover {
      text-decoration: none;
    }
  }

  margin-bottom: 1rem;
}
