@font-face {
  font-family: 'Averta PE';
  src: asset-url('35674A_0_0.eot'); /* IE9 Compat Modes */
  src: asset-url('35674A_0_0.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      asset-url('35674A_0_0.woff2') format('woff2'), /* Super Modern Browsers */
      asset-url('35674A_0_0.woff') format('woff'), /* Pretty Modern Browsers */
      asset-url('35674A_0_0.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: bold;
	font-style: normal;
}
@font-face {
  font-family: 'Averta PE';
  src: asset-url('35674A_1_0.eot'); /* IE9 Compat Modes */
  src: asset-url('35674A_1_0.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      asset-url('35674A_1_0.woff2') format('woff2'), /* Super Modern Browsers */
      asset-url('35674A_1_0.woff') format('woff'), /* Pretty Modern Browsers */
      asset-url('35674A_1_0.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: bold;
	font-style: italic;
}
@font-face {
  font-family: 'Averta PE';
  src: asset-url('35674A_2_0.eot'); /* IE9 Compat Modes */
  src: asset-url('35674A_2_0.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      asset-url('35674A_2_0.woff2') format('woff2'), /* Super Modern Browsers */
      asset-url('35674A_2_0.woff') format('woff'), /* Pretty Modern Browsers */
      asset-url('35674A_2_0.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
	font-style: normal;
}
@font-face {
  font-family: 'Averta PE';
  src: asset-url('35674A_3_0.eot'); /* IE9 Compat Modes */
  src: asset-url('35674A_3_0.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      asset-url('35674A_3_0.woff2') format('woff2'), /* Super Modern Browsers */
      asset-url('35674A_3_0.woff') format('woff'), /* Pretty Modern Browsers */
      asset-url('35674A_3_0.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
	font-style: italic;
}
