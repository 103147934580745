.match-overview {
  position: relative;
}

.match-overview__distance {
  position: absolute;
  top: map-get($spacers, 3);
  right: map-get($spacers, 3);
  height: map-get($spacers, 6);
  width: map-get($spacers, 6);
  line-height: map-get($spacers, 6);
  text-align: center;
  z-index: $zindex-modal-backdrop;
}

.match-overview__elapsed_time {
  height: map-get($spacers, 6);
  width: map-get($spacers, 6);
  line-height: map-get($spacers, 6);
  text-align: center;
  z-index: $zindex-modal-backdrop;
}

.match-overview__team {
  min-height: map-get($spacers, 6);
}

.match-overview__meta {
  min-height: map-get($spacers, 6);
}

.break-word {
  word-break: break-word;
}

.overflow-hidden {
  overflow: hidden;
}
