@each $color, $value in $theme-colors {

  .btn-#{$color} {
    border-radius: 4px;
    height: 40px;

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.25);
    };
  }
}

@mixin button-icon-variant($background) {
  @include button-variant($background, $background);
  border-radius: 50%;
  height: 40px;
  width: 40px;

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  }

  & > .icon {
    font-size: 1.4em;
    margin-left: -4px;
    margin-top: 2px;
  }
}

.btn {
  text-transform: uppercase;
}

.btn.btn-sm {
  height: auto;
}

.btn-icon {
  @include button-icon-variant(#ebebeb);
}

.btn-icon-light {
  @include button-icon-variant(#ffffff);
}

a.btn-facebook {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 0;
  width: 100%;
  color: inherit;
  background: #4267b2;
  color: white;
  text-align: center;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }
}

a.btn-facebook.disconnect {
  background: #f44336;
}

.btn-filter {
  color: $gray-600;
  font-weight: bold;

  &:hover {
    color: $gray-800;
  }
}
