$cookies-eu-background-color: #F2F2F2;
$cookies-eu-border-color: #E4E4E4;
$cookies-eu-button-background-color: #4D90FE;
$cookies-eu-button-color: #FFFFFF;
$cookies-eu-mobile: 'max-width: 767px';

.cookies-eu {
  line-height: 40px;

  position: fixed;
  z-index: 999;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 0 20px;

  text-align: center;

  border-top: 1px solid $cookies-eu-border-color;
  background: $cookies-eu-background-color;

  @media ($cookies-eu-mobile) {
    font-size: 12px;
    line-height: 30px;

    position: initial;
  }

  .cookies-eu-content-holder {
    display: inline-block;

    padding: 0 20px;
  }

  .cookies-eu-button-holder {
    display: inline-block;

    .cookies-eu-ok {
      font-size: 11px;
      font-weight: bold;

      line-height: 20px;

      min-width: 26px;
      margin-right: 5px;
      padding: 2px 11px;

      cursor: pointer;
      text-align: center;
      white-space: nowrap;

      color: $cookies-eu-button-color;
      border: 0;
      outline: none;
      background-color: $cookies-eu-button-background-color;
    }
  }
}
