.emoji-wysiwyg-editor {
  overflow-y: scroll;
}

.emoji-picker-icon {
  right: 5px;
}

.emoji-picker-icon:before {
  content: "";
  display: block;
  height: 24px;
  width: 24px;
  background-image: asset_url("emoji-picker/IconsetSmiles");
  background-position: -9px -34px;
  background-size: 42px 350px;
  margin-right: 10px;
}

.emoji-picker-icon:hover {
  cursor: pointer;
}

.emoji-wysiwyg-editor.parent-has-scroll {
  padding-right: 40px;
 }

.emoji-wysiwyg-editor.single-line-editor {
  min-height: 35px;
  height: inherit;
}

.emoji-menu-tabs {
  border: 0;
}

.emoji-items-wrap {
  height: 240px;
  overflow-y: scroll;
}

.emoji-picker-textarea {
  height: 180px;
}
