.clickable {
  cursor: pointer;
  &-row {
    @extend .clickable;
    &:hover {
      background-color: $gray-100;
    }
  }
}

table {
  border-top: 2px solid $gray-600;
  border-bottom: 1px solid #e0e0e0;
  empty-cells: show;
  td {
    padding-left: 0px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .table.split-table-mobile tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .table.split-table-mobile th,
  .table.split-table-mobile td {
    display: flex;
    width: 50%;
  }
}

%missing-image {
  height: 84px;
  line-height: 84px;
  color: $gray-600;
  font-family: "Averta PE";
  vertical-align: middle;
  text-align: center;
  &-sm {
    @extend .missing-image;
    height: 15px;
    line-height: 15px;
  }
}

.missing-image {
  @extend %missing-image;
}

td.dark-link {
  & > a {
    color: $gray-600;

    &:hover {
      text-decoration: none;
    }
  }

  margin-bottom: 1rem;
}
