// Override
.twitter-typeahead {
  width: 100% !important;
}

// From: bassjobsen/typeahead.js-bootstrap4-css
span.twitter-typeahead {
  .tt-menu {
    @extend .dropdown-menu;
    max-height: 25vh;
    overflow-y: scroll;
  }

  .tt-suggestion {
    @extend .dropdown-item;
    cursor: pointer;
  }

  .tt-suggestion.tt-cursor {
    @extend .dropdown-item, .active;
  }

  .input-group & {
    display: flex !important;
    align-items: center;
    position: relative;
    flex: 1 1 auto;
    width: 1%;

    .tt-menu,
    .tt-hint,
    .tt-input {
      width: 100%;
    }
  }
}

