/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, vendor/assets/stylesheets,
 * or vendor/assets/stylesheets of plugins, if any, can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the top of the
 * compiled file, but it's generally better to create a new file per style scope.
 */
@import "fonts";
@import "bootstrap/variables";
@import "bootstrap";
@import "dragula";
@import "typeahead";
@import "emoji";
@import "font-awesome";
@import "jquery-ui.css";
@import "admin/bootstrap-datetimepicker.css";

$header-spacer: map-get($spacers, 4);

// Customize bootstrap
@import "bootstrap/alerts";
@import "bootstrap/badges";
@import "bootstrap/buttons";
@import "bootstrap/card";
@import "bootstrap/dropdown";
@import "bootstrap/forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/pagination";
@import "bootstrap/text";

// keepr specific styles
@import "backlink";
@import "supportlink";
@import "checkbox_selector";
@import "dashboard";
@import "icons";
@import "login";
@import "tables";
@import "modal";
@import "match_overview";
@import "corner_banner";
@import "lineup_table_row";
@import "emoji_picker";
@import "liveticker_lineup";
@import "cookies_eu";
@import "match_day";
@import "loader";

html,
body {
  height: 100%;
}

img {
  max-width: 100%;
}

.object-fit-contain {
  object-fit: contain;
}

.bg-keepr-deep-blue {
  background-color: $keepr-deep-blue;
}

.dropdown-menu {
  z-index: $zindex-tooltip;
}

.w-md {
  min-width: 256px;
}

.w-md-dtc {
  min-width: 356px;
}

.h-md {
  height: 192px;
}

.h-lg {
  min-height: 256px;
}

.progress-bar-md {
  min-width: 3em;
}

.line-height-solid {
  line-height: 1.2;
}

.left-md {
  left: -96px;
}

.cursor-pointer {
  cursor: pointer;
}

.vertical-align-top {
  vertical-align: top;
}

.active-dot {
  height: 9px;
  width: 9px;
  background-color: #4bc33d;
  float: right;
  border-radius: 50%;
  display: inline-block;
}

.inactive-dot {
  height: 9px;
  width: 9px;
  background-color: #e24e4e;
  float: right;
  border-radius: 50%;
  display: inline-block;
}

.wide-container {
  max-width: 1500px;
}

.privacy-policy-link a {
  color: #ffffff !important;
}
