.page-link {
  &:hover {
    z-index: 1;
  }
  
  &:focus {
    z-index: 3;
  }
}

.page-item {
  &.active .page-link {
    z-index: 2;
  }
}
