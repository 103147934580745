.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: text-bottom;
}
.icon-sm {
  width: 3em;
  height: 4em;
}

.alert > .icon {
  font-size: 1.4em;
}

.btn > .icon {
  font-size: 1.2em;
}

.navbar .icon {
  font-size: 1.5em;
}

.icon-magenta {
  fill: $keepr-magenta;
}

.carousel-control-prev-icon {
 background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%239e9e9e' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%239e9e9e' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators li {
  background-color: $gray-300;
}

.carousel-indicators .active {
  background-color: $gray-900;
}

.set-middle-icon-size {
  width: 25px;
  height: 25px;
}
